import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de05562e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "estimate-table-container" }
const _hoisted_2 = { style: {"margin-right":"5px"} }
const _hoisted_3 = { class: "estimate-table-empty" }
const _hoisted_4 = { class: "estimate-table-footer" }
const _hoisted_5 = { style: {"padding-top":"20px","display":"flex","justify-content":"flex-end"} }
const _hoisted_6 = { class: "container-count" }
const _hoisted_7 = { style: {"display":"flex"} }
const _hoisted_8 = { style: {"margin-right":"5px"} }
const _hoisted_9 = { style: {"margin-right":"5px"} }
const _hoisted_10 = { style: {"margin-right":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_safety_inspection_questionnaire = _resolveComponent("safety-inspection-questionnaire")!
  const _component_b_banner = _resolveComponent("b-banner")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_quantity_picker = _resolveComponent("quantity-picker")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_floor_trak_orbis_card = _resolveComponent("floor-trak-orbis-card")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_floor_trak_orbis_card, {
      title: _ctx.getTitleCaseTranslation('core.common.estimate')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_safety_inspection_questionnaire, {
          modelValue: _ctx.state.showPostUnloadSafetyInspection,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showPostUnloadSafetyInspection) = $event)),
          carriers: _ctx.state.carriers,
          "inspection-type": _ctx.SafetyInspectionType.PostUnload,
          shipment: (_ctx.state.shipment as Shipment),
          "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
          onPassed: _ctx.unloadComplete
        }, null, 8, ["modelValue", "carriers", "inspection-type", "shipment", "inspection-title", "onPassed"]),
        (_ctx.state.shipment.isEstimateFinalized())
          ? (_openBlock(), _createBlock(_component_b_banner, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getTranslation('core.validation.receiptAlreadyEstimated')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_b_row, { class: "estimate-head" }, {
          default: _withCtx(() => [
            _createVNode(_component_text_input, {
              modelValue: _ctx.state.shipment.id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.shipment.id) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.shipmentWithNumberSymbol'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.transaction.id) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.transactionWithNumberSymbol'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.fromLocation.name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.transaction.fromLocation.name) = $event)),
              label: _ctx.getTitleCaseTranslation('core.common.from'),
              cols: "4",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.toLocation.name,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.transaction.toLocation.name) = $event)),
              label: _ctx.getTitleCaseTranslation('core.common.to'),
              cols: "4",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.state.shipment.dockName,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.shipment.dockName) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.dockDoor'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              "model-value": _ctx.state.shipment.carrierName,
              label: _ctx.getTitleCaseTranslation('core.domain.carrier'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["model-value", "label", "loading"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_b_table, {
            items: _ctx.tableData,
            fields: _ctx.fields,
            "row-height": "64px"
          }, {
            "cell(imageUrlThumb)": _withCtx((row) => [
              _createVNode(_component_thumbnail, {
                "image-url-thumb": row.item.imageUrlThumb,
                "image-url-full": row.item.imageUrlFull
              }, null, 8, ["image-url-thumb", "image-url-full"])
            ]),
            "cell(currentEstimatedQuantity)": _withCtx((row) => [
              _createElementVNode("span", null, _toDisplayString(row.item.currentEstimatedQuantity), 1)
            ]),
            "cell(estimateAdjustment)": _withCtx((row) => [
              (!_ctx.state.shipment.isEstimateFinalized())
                ? (_openBlock(), _createBlock(_component_quantity_picker, {
                    key: 0,
                    modelValue: row.item.estimateAdjustment,
                    "onUpdate:modelValue": ($event: any) => ((row.item.estimateAdjustment) = $event),
                    min: -999999,
                    onChange: (qty) => _ctx.changeQty(row.item.item, qty)
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
                : _createCommentVNode("", true)
            ]),
            "cell(action)": _withCtx((row) => [
              _createElementVNode("span", _hoisted_2, [
                _createVNode(_component_b_button, {
                  variant: "primary",
                  disabled: row.item.estimateAdjustment == 0 || _ctx.state.submittingEstimate === row.item.item.id,
                  onClick: () => _ctx.submitEstimate(row.item)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')) + " ", 1),
                    (_ctx.state.submittingEstimate === row.item.item.id)
                      ? (_openBlock(), _createBlock(_component_b_spinner, {
                          key: 0,
                          small: ""
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"])
              ]),
              _createVNode(_component_b_button, {
                variant: "primary",
                onClick: () => _ctx.removeItem(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.remove')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            empty: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getTranslation('core.common.scanOrAddToStart')), 1)
            ]),
            _: 1
          }, 8, ["items", "fields"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_b_button, {
              disabled: _ctx.state.shipment.isEstimateFinalized(),
              class: "btn-add-item",
              onClick: _ctx.openItemSearch
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.add')), 1),
                _createVNode(_component_faicon, { icon: "box-open" })
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showItemSearch,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.showItemSearch) = $event)),
      "hide-footer": "",
      title: _ctx.getTitleCaseTranslation('core.button.addItem')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_item_picker, {
          "item-type": _ctx.ItemType.RECEIVING,
          "allow-quantity-change": "",
          "ignore-combined-containers": "",
          onOnSelect: _ctx.addItem
        }, null, 8, ["item-type", "onOnSelect"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showSearch,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.showSearch) = $event)),
      "disable-escape-key": "",
      "hide-footer": "",
      "hide-header-close": "",
      title: _ctx.getTitleCaseTranslation('core.common.estimate'),
      onEnter: _ctx.searchExisting
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "close-search-modal",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.escape && _ctx.escape(...args)))
        }, [
          _createVNode(_component_faicon, { icon: "times" })
        ]),
        _createVNode(_component_b_form, { onSubmit: _ctx.searchExisting }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_input, {
              modelValue: _ctx.state.search,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.search) = $event)),
              type: "number",
              placeholder: _ctx.getTranslation('core.common.scanOrLookupTransactionNumber'),
              autofocus: ""
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_b_button, {
                type: "submit",
                disabled: _ctx.state.searching
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.search')) + " ", 1),
                  (_ctx.state.searching)
                    ? (_openBlock(), _createBlock(_component_b_spinner, {
                        key: 0,
                        small: ""
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onEnter"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showSaveModal,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.showSaveModal) = $event)),
      centered: "",
      title: _ctx.state.modalTitle,
      onEnter: _ctx.onModalOk
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "light",
          onClick: _ctx.onModalCancel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_b_button, {
          variant: "primary",
          onClick: _ctx.onModalOk
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.ok')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.getTranslation('core.common.areYouSure')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onEnter"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showPrintLabelsModal,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.showPrintLabelsModal) = $event)),
      centered: "",
      title: _ctx.getTranslation('core.common.numberOfLabelsToPrint'),
      onEnter: _ctx.printLabels
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          disabled: _ctx.state.printingLabels,
          onClick: _ctx.printLabels
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.print')) + " ", 1),
            (_ctx.state.printingLabels)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_form_input, {
          modelValue: _ctx.state.labelQuantity,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.labelQuantity) = $event)),
          placeholder: _ctx.getTranslation('core.common.numberOfLabelsToPrint'),
          autofocus: "",
          type: "number"
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onEnter"]),
    _createVNode(_component_smart_trak_footer, { "content-direction": "space-between" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_ctx.transaction.totalEstimatedItems) + " " + _toDisplayString(_ctx.getTitleCaseTranslation('core.common.totalEstimatedContainers')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_b_button, {
              disabled: !_ctx.state.unloadComplete || _ctx.state.shipment.isEstimateFinalized(),
              onClick: _ctx.finalizeEstimate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.finalizeEstimate')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _createElementVNode("span", _hoisted_9, [
            _createVNode(_component_b_button, {
              disabled: _ctx.state.unloadComplete,
              onClick: _ctx.showPostUnloadSafetyInspection
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.unloadComplete')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _createElementVNode("span", _hoisted_10, [
            _createVNode(_component_b_dropdown, {
              title: _ctx.getTitleCaseTranslation('core.common.print'),
              "close-on-click": "",
              "drop-up": "",
              "no-caret": "",
              "open-on-hover": "",
              "is-button": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_dropdown_item, {
                  disabled: !_ctx.canSubmit,
                  onClick: _ctx.printInboundReceipt
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.inboundReceipt')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]),
                _createVNode(_component_b_dropdown_item, {
                  disabled: !_ctx.canSubmit,
                  onClick: _ctx.openPrintLabelsModal
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.labels')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createVNode(_component_b_dropdown, {
            title: _ctx.getTitleCaseTranslation('core.common.receivingActions'),
            "close-on-click": "",
            "drop-up": "",
            "no-caret": "",
            "open-on-hover": "",
            "is-button": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, { onClick: _ctx.onCancel }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_b_dropdown_item, {
                disabled: !_ctx.state.shipment.isEstimateFinalized() || !_ctx.canSubmit,
                onClick: _ctx.onSort
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.saveAndSort')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                disabled: _ctx.state.shipment.isEstimateFinalized() || !_ctx.canSubmit,
                onClick: _ctx.onPutAway
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.saveAndPutAway')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          }, 8, ["title"])
        ])
      ]),
      _: 1
    })
  ], 64))
}