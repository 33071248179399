
import {
    computed, defineComponent, onBeforeMount, onBeforeUnmount, reactive,
} from 'vue';
import Shipment from '@/domain/Shipment';
import TagListener from '@/modules/floortrak/services/TagListener';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import Location from '@/domain/Location';
import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import ItemPicker from '@/components/ItemPicker.vue';
import { ItemType } from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import QuantityPicker from '@/components/QuantityPicker.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import ReceivingService from '@/services/ReceivingService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import router from '@/router';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import useLoading from '@/modules/floortrak/composables/useLoading';
import FloorTrakOrbisCard from '@/components/FloorTrakOrbisCard.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import LabelGenerationService from '@/services/LabelGenerationService';
import TrackedItemService from '@/services/tag-scanning/TrackedItemService';
import Thumbnail from '@/components/Thumbnail.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import { useNotification } from '@/composable/useNotifications';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import Carrier from '@/domain/Carrier';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import { CoreStore } from '@/store/CoreStore';
import BButton from '@/components/bootstrap-library/BButton.vue';
import SafetyInspectionService from '@/services/SafetyInspectionService';
import SafetyInspection from '@/domain/SafetyInspection';

type NextView = 'exit' | 'sort' | 'putAway';

type State = {
    loading: boolean;
    search: number | undefined;
    shipment: Shipment;
    showItemSearch: boolean;
    showSearch: boolean;
    searching: boolean;
    showSaveModal: boolean;
    showPrintLabelsModal: boolean;
    modalTitle: string;
    nextView: NextView | undefined;
    carriers: Carrier[];
    labelQuantity: number | undefined;
    printingLabels: boolean;
    showPostUnloadSafetyInspection: boolean;
    submittingEstimate: number | null;
    unloadComplete: boolean;
    postUnloadSafetyInspection: SafetyInspection;
};

interface ItemTableData {
    item: Item;
    name: string;
    currentEstimatedQuantity: number;
    estimateAdjustment: number;
    imageUrlThumb: string | undefined;
    imageUrlFull: string | undefined;
}

export default defineComponent({
    name: 'estimate',
    components: {
        BButton,
        Thumbnail,
        BSpinner,
        BFormInput,
        QuantityPicker,
        ItemPicker,
        BTable,
        FloorTrakOrbisCard,
        SmartTrakFooter,
        BModal,
        SafetyInspectionQuestionnaire,
    },
    props: {
        transactionNumber: {
            type: String,
            required: false,
            default: undefined,
        }, // this is a route param, it has to be a string => convert to number onSearch
    },
    setup(props) {
        const { carrierStore } = CoreStore.getInstance();
        const receivingService = new ReceivingService();
        const labelGenerationService = new LabelGenerationService();
        const trackedItemService = new TrackedItemService();
        const safetyInspectionService = new SafetyInspectionService();

        const { setLoading } = useLoading();
        const notification = useNotification();
        const { confirm } = useDialogBox();

        const state = reactive<State>({
            loading: false,
            search: undefined,
            shipment: new Shipment(),
            showItemSearch: false,
            showSearch: true,
            searching: false,
            showSaveModal: false,
            showPrintLabelsModal: false,
            modalTitle: getTitleCaseTranslation('core.common.receiving'),
            nextView: undefined,
            carriers: carrierStore.carriers,
            labelQuantity: undefined,
            printingLabels: false,
            showPostUnloadSafetyInspection: false,
            submittingEstimate: null,
            unloadComplete: false,
            postUnloadSafetyInspection: new SafetyInspection(),
        });

        function closeSearch() {
            state.search = undefined;
            state.showSearch = false;
        }

        const transaction = computed(
            (): Transaction => {
                if (state.shipment.transactions.length > 0) {
                    return state.shipment.transactions[0] as Transaction;
                }
                return new Transaction();
            },
        );
        const canSubmit = computed((): boolean => transaction.value.transactionLines?.length > 0);

        async function searchExisting() {
            if (state.search) {
                state.searching = true;
                const shipment = await receivingService.findReceivingShipment(state.search, ReceivingAction.ESTIMATE);
                if (shipment) {
                    state.shipment = shipment;
                    closeSearch();
                }
                
                state.unloadComplete = await safetyInspectionService.trailerInspectionOfTypePassesForShipment(state.shipment.id!, SafetyInspectionType.PostUnload);
                state.searching = false;
            }
        }

        async function addItem(data: { item: Item; quantity: number }) {
            if (transaction.value) {
                transaction.value.addEstimatedItemQty(data.item, data.quantity);
                const saveData = await receivingService.submitEstimate(transaction.value);
                if (saveData.success) {
                    state.shipment.transactions[0] = saveData.data;
                }
            }
            state.showItemSearch = false;
        }

        async function searchItem(tags: Array<string>) {
            const response = await trackedItemService.getTrackedItemsInRouteConfig(tags, transaction.value.fromLocationId, transaction.value.toLocationId);
            if (response) {
                response.forEach((trackedItem) => {
                    addItem({ item: trackedItem.item, quantity: 0 });
                });
            }
        }

        const tagListener = new TagListener(searchItem);

        onBeforeMount(async () => {
            tagListener.startListener();
            state.shipment.addTransaction(new Transaction());
            state.shipment.transactions[0].toLocation = new Location({
                id: 0,
                name: '',
            });
            if (props.transactionNumber) {
                state.showSearch = false;
                state.search = parseInt(props.transactionNumber, 10);
                await searchExisting();
                if (!state.shipment?.id) {
                    state.showSearch = true;
                }
            }
        });

        onBeforeUnmount(() => tagListener.stopListener());

        function mapTransLinesToTableData(lines: Array<TransactionLine>): Array<ItemTableData> {
            return lines.map((line) => ({
                item: line.item,
                name: line.item.name,
                currentEstimatedQuantity: line.estimatedQuantity,
                estimateAdjustment: line.estimateAdjustment,
                imageUrlFull: line.item.imageUrlFull,
                imageUrlThumb: line.item.imageUrlThumb,
            }));
        }

        const tableData = computed(
            (): Array<ItemTableData> => {
                if (transaction.value && transaction.value.transactionLines) {
                    return mapTransLinesToTableData(transaction.value.transactionLines as Array<TransactionLine>);
                }
                return [];
            },
        );

        const fields = computed(
            (): Array<BTableField<ItemTableData & { action: string }>> => {
                const baseFields: Array<BTableField<ItemTableData & { action: string }>> = [
                    {
                        key: 'imageUrlThumb',
                        label: getTitleCaseTranslation('core.common.image'),
                        width: '70px',
                        ignoreSort: true,
                    },
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                    },
                    {
                        key: 'currentEstimatedQuantity',
                        width: '225px',
                        label: getTitleCaseTranslation('core.common.totalEstimatedContainers'),
                    },
                    {
                        key: 'estimateAdjustment',
                        width: '300px',
                        label: getTitleCaseTranslation('core.domain.additionalEstimatedQuantity'),
                    },
                ];
                if (!state.shipment.isEstimateFinalized()) {
                    baseFields.push({
                        key: 'action',
                        label: getTitleCaseTranslation('core.common.action'),
                        width: '300px',
                        align: 'right',
                    });
                }

                return baseFields;
            },
        );

        function openItemSearch() {
            state.showItemSearch = true;
        }

        async function removeItem(data: ItemTableData) {
            transaction.value.removeTransactionLineByItem(data.item);
            const saveData = await receivingService.submitEstimate(transaction.value);

            if (saveData.success) {
                state.shipment.transactions[0] = saveData.data;
            }
        }

        async function submitEstimate(data: ItemTableData) {
            state.submittingEstimate = data.item.id;
            transaction.value.setEstimatedItemQty(data.item, data.currentEstimatedQuantity + data.estimateAdjustment);
            state.loading = true;
            const saveData = await receivingService.submitEstimate(transaction.value);
            if (saveData.success) {
                state.shipment.transactions[0] = saveData.data;
            }
            state.submittingEstimate = null;
            state.loading = false;
        }

        function changeQty(item: Item, qty: number) {
            transaction.value.setEstimateAdjustment(item, qty);
        }

        function onCancel() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function onExit(): void {
            state.modalTitle = getTitleCaseTranslation('core.button.saveAndExit');
            state.showSaveModal = true;
            state.nextView = 'exit';
        }

        function onSort(): void {
            state.modalTitle = getTitleCaseTranslation('core.button.saveAndSort');
            state.showSaveModal = true;
            state.nextView = 'sort';
        }

        function onPutAway(): void {
            state.modalTitle = getTitleCaseTranslation('core.button.saveAndPutAway');
            state.showSaveModal = true;
            state.nextView = 'putAway';
        }

        function onModalCancel(): void {
            state.showSaveModal = false;
            state.showPrintLabelsModal = false;
            state.nextView = undefined;
        }

        async function onModalOk() {
            if (canSubmit.value) {
                setLoading(true);
                const response = await receivingService.submitEstimate(transaction.value);
                setLoading(false);
                if (response) {
                    if (state.nextView === 'exit') {
                        await router.push({ name: FloorTrakRouteTypes.HOME });
                    } else {
                        await router.push({
                            name: state.nextView === 'sort' ? FloorTrakRouteTypes.RECEIVING.SORT : FloorTrakRouteTypes.RECEIVING.PUT_AWAY,
                            params: { transactionNumber: transaction.value.id },
                        });
                    }
                }
            }
        }

        function escape() {
            closeSearch();
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function openPrintLabelsModal(): void {
            state.showPrintLabelsModal = true;
        }

        async function saveBefore() {
            // if we've already estimated, no need to save again
            if (state.shipment.isEstimateFinalized()) {
                return true;
            }

            let response = await confirm({
                title: getTitleCaseTranslation('core.button.saveAndPrint'),
                message: getTranslation('core.validation.changesSavedBeforePrintingAreYouSure'),
            });
            if (response) {
                state.loading = true;
                response = (await receivingService.submitEstimate(transaction.value)).success;
                state.loading = false;
            }
            return response;
        }

        async function printLabels() {
            state.showPrintLabelsModal = false;

            if (!state.labelQuantity) {
                notification.showError(getTranslation('core.validation.quantityMustBeSpecified'));
                return;
            }

            if (!(await saveBefore())) {
                return;
            }

            state.printingLabels = true;
            const labelUrl = await labelGenerationService.getInboundLabelsUrl(transaction.value.id, state.labelQuantity!);
            state.printingLabels = false;
            state.labelQuantity = undefined;
            if (labelUrl) {
                window.location.href = labelUrl;
            }
        }

        async function printInboundReceipt() {
            if (!(await saveBefore())) {
                return;
            }

            state.loading = true;
            setLoading(true);
            await receivingService.getTransactionReceipt(transaction.value.id);
            setLoading(false);
            state.loading = false;
        }

        async function finalizeEstimate() {
            const confirmFinalize = await confirm({
                title: getTitleCaseTranslation('core.domain.finalizeEstimate'),
                message: getTranslation('core.domain.confirmFinalizeMessage'),
            });
            if (confirmFinalize) {
                state.loading = true;
                const response = await receivingService.finalizeEstimate(transaction.value);
                if (response.success) {
                    state.shipment.transactions[0] = response.transaction;
                }
                state.loading = false;
            }
        }

        function showPostUnloadSafetyInspection() {
            state.showPostUnloadSafetyInspection = true
        }

        async function unloadComplete(inspection: SafetyInspection) {
            state.postUnloadSafetyInspection = inspection;
            state.unloadComplete = true;
            await safetyInspectionService.associateShipmentWithTrailerInspection(state.shipment.id!, state.postUnloadSafetyInspection.id);
        }

        return {
            state,
            transaction,
            fields,
            ItemType,
            addItem,
            openItemSearch,
            tableData,
            changeQty,
            searchExisting,
            onCancel,
            onSort,
            onPutAway,
            onExit,
            onModalCancel,
            onModalOk,
            removeItem,
            submitEstimate,
            finalizeEstimate,
            canSubmit,
            escape,
            openPrintLabelsModal,
            printLabels,
            printInboundReceipt,
            getTranslation,
            getTitleCaseTranslation,
            SafetyInspectionType,
            showPostUnloadSafetyInspection,
            unloadComplete,
        };
    },
});
